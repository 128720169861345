import React, { createContext, useState, useContext, useEffect } from 'react';
import { api } from './utils';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [config, setConfig] = useState({});

    useEffect(() => {
        async function check(){
            let bearerToken = Cookies.get('bearer_token');
            if(!bearerToken){
                setIsAuthenticated(false);
                return
            }

            let configData = await api.getUserConfig()
            if(configData.id){
                setConfig(configData);
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        }
        check()
    }, []);

    if(isAuthenticated !== null){
        return (
            <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, config, setConfig }}>
                {children}
            </AuthContext.Provider>
        );
    }

    return (
        <div></div>
    );
};

export const useAuth = () => useContext(AuthContext);
