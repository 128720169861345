import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function OrderTask({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        if(initialData.id){
            await api.jsonRpcRequest('order_tasks.update', {id: initialData.id, order_id: orderInfo.id, ...data });
        } else {
            await api.jsonRpcRequest('order_tasks.add', {order_id: orderInfo.id, ...data });
        }
        if(onCompleteFunction) onCompleteFunction(initialData.id)
    };

    const removeCustomHandler = async (objectId) => {
        await api.jsonRpcRequest('order_tasks.update', {id: initialData.id, completed_datetime: new Date() });
        if(onCompleteFunction) onCompleteFunction(initialData.id)
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrderTask.form.text'),
                'key': 'text',
                'type': 'text',
                'required': true,
            },
            {
                'title': translate('OrderTask.form.responsible_user'),
                'key': 'responsible_user_id',
                'type': 'select',
                'required': true,
                'options': config && config.users ? config.users.map(item => ({ value: item.id, label: item.full_name })) : [],
            },
            {
                'title': translate('OrderTask.form.deadline_datetime'),
                'key': 'deadline_datetime',
                'type': 'datetime',
                'required': true,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} removeCustomHandler={initialData.id ? removeCustomHandler : null} initialData={initialData} removeTitle={translate('OrderTask.form.complete_task')} />
        </div>
    );
}

export default OrderTask;
