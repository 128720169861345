import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function OrderEquipment({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        if(initialData.id){
            await api.jsonRpcRequest('orders.equipments.update', {id: initialData.id, order_id: orderInfo.id, ...data });
        } else {
            await api.jsonRpcRequest('orders.equipments.add', {order_id: orderInfo.id, ...data });
        }
        if(onCompleteFunction) onCompleteFunction(initialData.id)
    };

    const removeCustomHandler = async (objectId) => {
        await api.jsonRpcRequest('orders.equipments.remove', {id: initialData.id });
        if(onCompleteFunction) onCompleteFunction(initialData.id)
    };

    const renderSuggestion = (suggestion) => (
        <div>
            {suggestion.name}
        </div>
    );

    const getServiceSuggestions = async (value, key) => {
        const inputValue = value.trim().toLowerCase();

        let data = await api.jsonRpcRequest('equipments.list', { filters: { name__icontains: inputValue } });
        data = data.objects.map(item => {
            return { ...item, id: item.id, name: item.name };
        });
        return data;
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrderEquipment.form.equipment'),
                'key': 'equipment_id',
                'getSuggestionsFunction': getServiceSuggestions,
                'renderSuggestionFunction': renderSuggestion,
                'type': 'autocomplete',
                'required': true,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} removeCustomHandler={initialData.id ? removeCustomHandler : null} initialData={initialData} />
        </div>
    );
}

export default OrderEquipment;
