import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import { toast } from 'react-toastify';
import OrderAddress from '../components/OrderAddress';
import OrderItem from '../components/OrderItem';
import OrderEmployee from '../components/OrderEmployee';
import OrderEquipment from '../components/OrderEquipment';
import OrderEmployeeSearch from '../components/OrderEmployeeSearch';
import OrderScheduledDateTime from '../components/OrderScheduledDateTime';
import { colors } from '../theme';
import DynamicForm from '../components/DynamicForm';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import Modal from 'react-bootstrap/Modal';
import '../App.css';

function JournalPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  let addonFilters = [];

  const handleActionButtonClick = (destination) => {
    navigate(destination);
  };

  const tableConfig = {
    'fields': [
      {
        'title': translate('JournalPage.table.fields.user_email'),
        'key': 'user_email',
        'order_key': 'user_id',
      },
      {
        'title': translate('JournalPage.table.fields.create_time'),
        'key': 'create_time',
        'type': 'date',
        'order_key': 'create_time',
      },
      {
        'title': translate('JournalPage.table.fields.content'),
        'key': 'content',
        'order_key': 'content',
      }
    ],
    'actionButtons': [
      {
        'title': translate('JournalPage.table.actionButtons.add_event'),
        'className': 'btn-success',
        'handler': () => {handleActionButtonClick('/journal/add')}
      }
    ],
    'filters': [
      {
        'title': translate('JournalPage.form.session_start'),
        'key': 'create_time__gte',
        'type': 'datetime',
      },
      {
        'title': translate('JournalPage.form.session_end'),
        'key': 'create_time__lte',
        'type': 'datetime',
      },
    ]
  };

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
    if (config && config.permissions && config.permissions.includes('journal_all')){
      tableConfig.filters.push(
        {
          'title': translate('JournalPage.table.fields.user_email'),
          'key': 'user_id',
          'type': 'select',
          'options': config && config.users ? config.users.map(item => ({ value: item.id, label: item.full_name })) : [],
        }
      );
    }
  }, [isAuthenticated, navigate, config]);

  return (
    <Menu>
      <Table method={'journal.list'} tableConfig={tableConfig} />
    </Menu>
  );
}


function JournalAddPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('JournalPage.table.fields.content'),
        'key': 'content',
        'type': 'text',
        'required': true,
      },
    ]
  };

  return (
    <Menu>
      <DynamicForm saveMethod={'journal.add'} formConfig={formConfig} listLocation={'/journal'} />
    </Menu>
  );
}


export { JournalPage };
export { JournalAddPage };