import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function DocumentMoveWarehouse({ documentInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        await api.jsonRpcRequest('documents.move_warehouse.add', { document_id: documentInfo.id, warehouse_id: data.warehouse_id });
        if(onCompleteFunction) onCompleteFunction()
    };

    const removeCustomHandler = async (objectId) => {
        await api.jsonRpcRequest('documents.move_warehouse.remove', { document_id: documentInfo.id });
        if(onCompleteFunction) onCompleteFunction()
    };
    const formConfig = {
        'fields': [
            {
                'title': translate('EquipmentsPage.table.fields.warehouse'),
                'key': 'warehouse_id',
                'type': 'select',
                'options': config && config.warehouses ? config.warehouses.map(item => ({ value: item.id, label: item.name })) : [],
            }
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} removeCustomHandler={initialData && initialData.id ? removeCustomHandler : null} initialData={initialData} />
        </div>
    );
}

export default DocumentMoveWarehouse;
