// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ru, uk } from './languages';

const resources = {
  uk: {
    translation: uk,
  },
  ru: {
    translation: ru,
  },
};

i18n
  .use(initReactI18next) // Initialize i18next for React
  .init({
    resources,
    fallbackLng: 'uk', // Fallback language
    lng: 'uk', // Default language
    interpolation: {
      escapeValue: false, // React does escaping by default
    },
  });

export default i18n;
