import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { toast } from 'react-toastify';

function OrderAddress({ orderInfo, onCompleteFunction }) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();
    const [mapCoords, setMapCoords] = useState([51.505, -0.09]);
    const [mapZoom, setMapZoom] = useState(13);
    const [addressData, setAddressData] = useState({});
    const [initialData, setInitialData] = useState({});

    useEffect(() => {
        setInitialData({...orderInfo});
        if(orderInfo.address_locality_id){
            const address_locality = config.localities.filter(item => item.id === parseInt(orderInfo.address_locality_id))[0];
            setInitialData({...orderInfo, ...{address_locality: `${address_locality.region}, ${address_locality.name}`}})
        }
        if(orderInfo.address_latitude && orderInfo.address_longitude){
            setMapCoords([orderInfo.address_latitude, orderInfo.address_longitude])
        }
    }, []);

    const onChangeForm = async (data) => {
        if (data.address_locality_id && addressData.address_locality_id && addressData.address_locality_id !== data.address_locality_id) {
            const address_locality = config.localities.filter(item => item.id === parseInt(data.address_locality_id))[0];
            setMapCoords([address_locality.lat, address_locality.lng]);
            setMapZoom(address_locality.zoom);
        }

        if (addressData.address_name !== data.address_name) {
            const address_locality = config.localities.filter(item => item.id === parseInt(data.address_locality_id))[0];
            let autocompleteData = await api.jsonRpcRequest('utils.autocomplete', { query: `${address_locality.region}, ${address_locality.name}, ${data.address_name}` });
            if (autocompleteData.length) {
                data = { ...data, ...autocompleteData[0] }
                setMapCoords([autocompleteData[0].latitude, autocompleteData[0].longitude])
            }

        }
        setAddressData({ ...addressData, ...data })
    };

    const saveCustomHandler = async (data) => {
        let updateData = {
            hash: orderInfo.hash,
            address_apartment: addressData.address_apartment,
            address_latitude: addressData.latitude,
            address_longitude: addressData.longitude,
            address_name: addressData.address_name,
            address_comment: addressData.address_comment,
            address_locality_id: addressData.address_locality_id,
        }
        await api.jsonRpcRequest('orders.update', updateData);
        toast.success(translate('common.toast.saved'))
        onCompleteFunction();
    };

    const getAddressSuggestions = async (value, key) => {
        if (!addressData || addressData && !addressData.address_locality_id) {
            return []
        }

        const inputValue = value.trim().toLowerCase();

        const address_locality = config.localities.filter(item => item.id === parseInt(addressData.address_locality_id))[0];

        let data = await api.jsonRpcRequest('utils.autocomplete', { query: `${address_locality.region}, ${address_locality.name}, ${inputValue}` });
        data = data.map(address => {
            return { id: address.address, name: address.address };
        });
        return data;
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrderAddress.form.address_locality'),
                'key': 'address_locality_id',
                'required': true,
                'type': 'select',
                'options': config && config.localities ? config.localities.map(item => ({ value: item.id, label: item.name })) : [],
            },
            {
                'title': translate('OrderAddress.form.address_name'),
                'key': 'address_name',
                'getSuggestionsFunction': getAddressSuggestions,
                'type': 'autocomplete',
                'required': true,
            },
            {
                'title': translate('OrderAddress.form.address_apartment'),
                'key': 'address_apartment',
                'type': 'text',
                'required': false,
            },
            {
                'title': translate('OrderAddress.form.address_comment'),
                'key': 'address_comment',
                'type': 'text',
                'required': false,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} onChangeForm={onChangeForm} mapCoords={mapCoords} mapZoom={mapZoom} initialData={orderInfo} />
        </div>
    );
}

export default OrderAddress;
