import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import { useAuth } from '../AuthContext';

function HomePage() {
  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
  }, [isAuthenticated, navigate]);

  return (
    <Menu>
      
    </Menu>
  );
}

export default HomePage;
