import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { toast } from 'react-toastify';

function OrderState({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        await api.jsonRpcRequest('orders.update', { hash: orderInfo.hash, ...data });
        if(onCompleteFunction) onCompleteFunction(initialData.id)
        toast.success(translate('common.toast.saved'))
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrdersPage.table.fields.state'),
                'key': 'state_id',
                'required': true,
                'type': 'select_group',
                'options': config && config.order_states ? config.order_states.reduce((acc, item) => { const group = acc.find(g => g.label === item.group_name); const option = { value: item.id, label: item.name }; group ? group.options.push(option) : acc.push({ label: item.group_name, options: [option] }); return acc; }, []) : [],
            }
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} initialData={initialData} />
        </div>
    );
}

export default OrderState;
