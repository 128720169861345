import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function OrderEmployeeAddPayment({ orderInfo, onCompleteFunction}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {

    }, []);

    const saveCustomHandler = async (data) => {
        let updateData = {
            order_id: orderInfo.id,
            amount: data.amount,
            employee_id: data.employee_id
        }
        await api.jsonRpcRequest('employee.payments.add', updateData);
        if(onCompleteFunction) onCompleteFunction();
    };

    const formConfig = {
        'fields': [
          {
            'title': translate('OrderEmployeeAddPayment.form.employee'),
            'key': 'employee_id',
            'type': 'select',
            'required': true,
            'options': orderInfo && orderInfo.employees ? orderInfo.employees.map(item => ({ value: item.employee.id, label: item.employee.full_name })) : [],
          },
          {
            'title': translate('OrderEmployeeAddPayment.form.amount'),
            'key': 'amount',
            'type': 'number',
            'required': true,
          },
        ]
      };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} initialData={orderInfo} />
        </div>
    );
}

export default OrderEmployeeAddPayment;
