import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import Table from '../components/Table';

function OrderPayments({ orderInfo, onCompleteFunction, addOrderPaymentHandler }) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();
    const [initialData, setInitialData] = useState({});

    useEffect(() => {
        setInitialData({ ...orderInfo });
    }, []);

    const tableConfig = {
        'fields': [
            {
                'title': translate('AccountingOperationsPage.table.fields.date'),
                'key': 'date',
                'type': 'date',
                'order_key': 'date',
            },
            {
                'title': translate('AccountingOperationsPage.table.fields.user_email'),
                'key': 'user_full_name',
                'order_key': 'user_id',
            },
            {
                'title': translate('AccountingOperationsPage.table.fields.amount'),
                'key': 'amount',
                'order_key': 'amount',
            },
            {
                'title': translate('AccountingOperationsPage.table.fields.currency'),
                'key': 'currency',
                'order_key': 'currency',
            },
            {
                'title': translate('AccountingOperationsPage.table.fields.wallet'),
                'key': 'wallet_name',
                'order_key': 'wallet__name',
            },
            {
                'title': translate('AccountingOperationsPage.table.fields.notes'),
                'key': 'notes',
                'order_key': 'notes',
            }
        ]
    };

    return (
        <div>
            <Table method={'accounting.operations.list'} tableConfig={tableConfig} initialFilters={{ order__hash: orderInfo.hash }} addPageHandler={addOrderPaymentHandler} />
        </div>
    );
}

export default OrderPayments;
