import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function OrderDocumentOut({ orderInfo, onCompleteFunction, initialData }) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        const infoData = await api.jsonRpcRequest('orders.articles.generate_out_document', { hash: orderInfo.hash, warehouse_id: data.warehouse_id });
        navigate(`/documents/${infoData.id}`);
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('EquipmentsPage.table.fields.warehouse'),
                'key': 'warehouse_id',
                'type': 'select',
                'options': config && config.warehouses ? config.warehouses.map(item => ({ value: item.id, label: item.name })) : [],
                'required': true,
            },
        ]
    };

    return (
        <div>

            <table className="table table-hover table-sm">
                <thead className="thead-dark">
                    <tr>
                        <th>{translate('OrderDocumentOut.article')}</th>
                        <th>{translate('OrderDocumentOut.warehouse')}</th>
                        <th>{translate('OrderDocumentOut.quantity')}</th>
                    </tr>
                </thead>
                <tbody>
                    {orderInfo.out_document_items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.warehouse_name}</td>
                            <td>{item.article_name}</td>
                            <td>{item.quantity}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default OrderDocumentOut;
