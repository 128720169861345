import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function OrderComment({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        await api.jsonRpcRequest('orders.comments.add', {order_id: orderInfo.id, ...data });
        if(onCompleteFunction) onCompleteFunction(initialData.id)
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrderComment.form.comment'),
                'key': 'content',
                'type': 'text',
                'required': true,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} initialData={initialData} />
        </div>
    );
}

export default OrderComment;
