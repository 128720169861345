import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import { toast } from 'react-toastify';
import DynamicForm from '../components/DynamicForm';


function TOTPPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();
  const [initialData, setInitialData] = useState(null);
  const [totpData, setTotpData] = useState({});

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    setTimeout(() => {
      fetchData();
    }, 1)
  }, []);

  const fetchData = async () => {
    const data = await api.jsonRpcRequest('user.profile.totp.info');
    if (data) {
      setInitialData({...data});
    }
  };

  const formConfig = {
    'fields': [
      {
        'title': translate('TOTPPage.form.totp_secret'),
        'key': 'totp_secret_url',
        'type': 'qr',
        'required': false,
      },
    ]
  };

  const saveCustomHandler = async (data) => {
    let updateData = {
      totp_secret: initialData.totp_secret
    }
    await api.jsonRpcRequest('user.profile.totp.update', updateData);
    toast.success(translate('common.toast.saved'))
  };

  const onChangeForm = async (data) => {
    setTotpData({ ...totpData, ...data })
  };

  if(!initialData) return

  return (
    <Menu>
      <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} onChangeForm={onChangeForm} initialData={initialData} />
    </Menu>
  );
}

export { TOTPPage };