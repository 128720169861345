import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import { toast } from 'react-toastify';
import OrderAddress from '../components/OrderAddress';
import OrderItem from '../components/OrderItem';
import OrderEmployee from '../components/OrderEmployee';
import OrderEquipment from '../components/OrderEquipment';
import OrderEmployeeSearch from '../components/OrderEmployeeSearch';
import OrderScheduledDateTime from '../components/OrderScheduledDateTime';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { colors } from '../theme';
import DynamicForm from '../components/DynamicForm';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import 'moment/locale/ru'; // Подключаем русскую локализацию moment.js
import '../App.css';

const localizer = momentLocalizer(moment);

// Настраиваем формат времени на 24 часа
const formats = {
  timeGutterFormat: (date, culture, localizer) =>
    localizer.format(date, 'HH:mm', culture),
  eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
    let startDate = localizer.format(start, 'HH:mm', culture);
    let endDate = localizer.format(end, 'HH:mm', culture);
    return `${startDate} - ${endDate}`;
  },
};


function AccountingOperationsPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
  }, [isAuthenticated, navigate]);

  const handleActionButtonClick = (destination) => {
    navigate(destination);
};

  const tableConfig = {
    'fields': [
      {
        'title': translate('AccountingOperationsPage.table.fields.date'),
        'key': 'date',
        'type': 'date',
        'order_key': 'date',
      },
      {
        'title': translate('AccountingOperationsPage.table.fields.user_email'),
        'key': 'user_full_name',
        'order_key': 'user_id',
      },
      {
        'title': translate('AccountingOperationsPage.table.fields.amount'),
        'key': 'amount',
        'order_key': 'amount',
      },
      {
        'title': translate('AccountingOperationsPage.table.fields.currency'),
        'key': 'currency',
        'order_key': 'currency',
      },
      {
        'title': translate('AccountingOperationsPage.table.fields.usd_amount'),
        'key': 'usd_amount',
        'order_key': 'usd_amount',
      },
      {
        'title': translate('AccountingOperationsPage.table.fields.expense_item_name'),
        'key': 'expense_item_name',
        'order_key': 'expense_item__name',
      },
      {
        'title': translate('AccountingOperationsPage.table.fields.wallet'),
        'key': 'wallet_name',
        'order_key': 'wallet__name',
      },
      {
        'title': translate('AccountingOperationsPage.table.fields.notes'),
        'key': 'notes',
        'order_key': 'notes',
      },
    ],
    'actionButtons': [
      {
        'title': translate('AccountingOperationsPage.table.actionButtons.adjuction'),
        'className': 'btn-success',
        'handler': () => {handleActionButtonClick('/accounting/adjuction')}
      },
      {
        'title': translate('AccountingOperationsPage.table.actionButtons.substraction'),
        'className': 'btn-warning',
        'handler': () => {handleActionButtonClick('/accounting/substraction')}
      },
      {
        'title': translate('AccountingOperationsPage.table.actionButtons.transfer'),
        'className': 'btn-danger',
        'handler': () => {handleActionButtonClick('/accounting/transfer')}
      },
      {
        'title': translate('AccountingOperationsPage.table.actionButtons.debts'),
        'className': 'btn-default',
        'handler': () => {handleActionButtonClick('/accounting/debts')}
      },
      {
        'title': translate('AccountingOperationsPage.table.actionButtons.total'),
        'className': 'btn-default',
        'handler': () => {handleActionButtonClick('/accounting/total')}
      },
    ],
    'filters': [
      {
        'title': translate('AccountingOperationsPage.table.fields.notes'),
        'key': 'notes',
        'type': 'text',
      },

      {
        'title': translate('EmployeeSessionsPage.form.session_start'),
        'key': 'date__gte',
        'type': 'datetime',
      },
      {
        'title': translate('EmployeeSessionsPage.form.session_end'),
        'key': 'date__lte',
        'type': 'datetime',
      }
    ]
  };

  return (
    <Menu>
      <Table method={'accounting.operations.list'} tableConfig={tableConfig} />
    </Menu>
  );
}

function AccountingOperationsAdjuctionPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('AccountingOperationsPage.form.amount'),
        'key': 'amount',
        'type': 'number',
        'required': true,
      },
      {
        'title': translate('AccountingOperationsPage.form.currency'),
        'key': 'currency',
        'type': 'select',
        'required': true,
        'options': config && config.currencies ? config.currencies.map(item => ({ value: item[0], label: item[1] })) : [],
      },
      {
        'title': translate('AccountingOperationsPage.form.wallet'),
        'key': 'wallet_id',
        'type': 'select',
        'required': true,
        'options': config && config.wallets ? config.wallets.map(item => ({ value: item.id, label: item.name })) : [],
      },
      {
        'title': translate('AccountingOperationsPage.form.notes'),
        'key': 'notes',
        'type': 'text',
        'required': false,
      },
    ]
  };

  return (
    <Menu>
      <DynamicForm saveMethod={'accounting.operations.adjuction'} formConfig={formConfig} listLocation={'/accounting'} />
    </Menu>
  );
}

function AccountingOperationsSubstractionPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();
  const [ expenseItems, setExpenseItems ] = useState([]);

  const loadExpenseItems = async () => {
    const data = await api.jsonRpcRequest('accounting.expense_items.list', { limit: 1000 });
    setExpenseItems(data.objects);
  };

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
    loadExpenseItems();
  }, [isAuthenticated, navigate]);

    const renderSuggestion = (suggestion) => (
      <div>
          {suggestion.full_name}
      </div>
  );

  const getServiceSuggestions = async (value, key) => {
      const inputValue = value.trim().toLowerCase();

      let data = await api.jsonRpcRequest('employees.list', { filters: { full_name__icontains: inputValue } });
      data = data.objects.map(item => {
          return { ...item, id: item.id, name: item.full_name };
      });
      return data;
  };

  const formConfig = {
    'fields': [
      {
        'title': translate('AccountingOperationsPage.form.amount'),
        'key': 'amount',
        'type': 'number',
        'required': true,
      },
      {
        'title': translate('AccountingOperationsPage.form.currency'),
        'key': 'currency',
        'type': 'select',
        'required': true,
        'options': config && config.currencies ? config.currencies.map(item => ({ value: item[0], label: item[1] })) : [],
      },
      {
        'title': translate('AccountingOperationsPage.form.expense_item'),
        'key': 'expense_item_id',
        'type': 'select',
        'required': true,
        'options': expenseItems ? expenseItems.map(item => ({ value: item.id, label: item.name })) : [],
      },
      {
        'title': translate('AccountingOperationsPage.form.wallet'),
        'key': 'wallet_id',
        'type': 'select',
        'required': true,
        'options': config && config.wallets ? config.wallets.map(item => ({ value: item.id, label: item.name })) : [],
      },
      {
        'title': translate('OrderEmployee.form.employee'),
        'key': 'employee_id',
        'getSuggestionsFunction': getServiceSuggestions,
        'renderSuggestionFunction': renderSuggestion,
        'type': 'autocomplete',
        'required': false,
      },
      {
        'title': translate('AccountingOperationsPage.form.notes'),
        'key': 'notes',
        'type': 'text',
        'required': false,
      },
    ]
  };

  return (
    <Menu>
      <DynamicForm saveMethod={'accounting.operations.substraction'} formConfig={formConfig} listLocation={'/accounting'} />
    </Menu>
  );
}

function AccountingOperationsTransferPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('AccountingOperationsPage.form.amount'),
        'key': 'amount',
        'type': 'number',
        'required': true,
      },
      {
        'title': translate('AccountingOperationsPage.form.currency'),
        'key': 'currency',
        'type': 'select',
        'options': config && config.currencies ? config.currencies.map(item => ({ value: item[0], label: item[1] })) : [],
      },
      {
        'title': translate('AccountingOperationsPage.form.to_user_id'),
        'key': 'to_user_id',
        'type': 'select',
        'options': config && config.users ? config.users.map(item => ({ value: item.id, label: item.full_name })) : [],
      },
    ]
  };

  return (
    <Menu>
      <DynamicForm saveMethod={'accounting.operations.transfer'} formConfig={formConfig} listLocation={'/accounting'} />
    </Menu>
  );
}

function AccountingOperationsDebtsPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
  }, [isAuthenticated, navigate]);

  const handleActionButtonClick = (destination) => {
    navigate(destination);
};

  const tableConfig = {
    'fields': [
      {
        'title': translate('AccountingOperationsPage.table.fields.debt_from'),
        'key': 'debtor_email',
        'order_key': 'date',
      },
      {
        'title': translate('AccountingOperationsPage.table.fields.debt_to'),
        'key': 'user_email',
        'order_key': 'user__email',
      },
      {
        'title': translate('AccountingOperationsPage.table.fields.amount'),
        'key': 'amount',
        'order_key': 'amount',
      },
    ],
  };

  return (
    <Menu>
      <Table method={'accounting.operations.debts'} tableConfig={tableConfig} />
    </Menu>
  );
}

function AccountingOperationsTotalPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
  }, [isAuthenticated, navigate]);

  if(!config || !config.currencies){
    return;
  }

  let fields = [
    {
      'title': translate('AccountingOperationsPage.table.fields.debt_from'),
      'key': 'user__full_name',
      'order_key': 'user__full_name',
    },
    {
      'title': translate('AccountingOperationsPage.table.fields.in'),
      'key': 'total_in',
      'order_key': 'total_in',
    },
    {
      'title': translate('AccountingOperationsPage.table.fields.out'),
      'key': 'total_out',
      'order_key': 'total_out',
    },
    {
      'title': translate('AccountingOperationsPage.table.fields.balance'),
      'key': 'total_balance',
      'order_key': 'total_balance',
    }
  ]

  config.currencies.forEach(function(obj) {
    let currencyField = {
      'title': `${translate('AccountingOperationsPage.table.fields.in')} ${obj[1]}`,
      'key': `total_${obj[0].toLowerCase()}_in`,
      'order_key': `total_${obj[0].toLowerCase()}_in`
    };
    fields.push(currencyField)
    
    currencyField = {
      'title': `${translate('AccountingOperationsPage.table.fields.out')} ${obj[1]}`,
      'key': `total_${obj[0].toLowerCase()}_out`,
      'order_key': `total_${obj[0].toLowerCase()}_out`,
    };
    fields.push(currencyField)
    
    currencyField = {
      'title': `${translate('AccountingOperationsPage.table.fields.balance')} ${obj[1]}`,
      'key': `total_${obj[0].toLowerCase()}_balance`,
      'order_key': `total_${obj[0].toLowerCase()}_balance`,
    };
    fields.push(currencyField)
  });

  let tableConfig = {
    'fields': fields,
  };

  return (
    <Menu>
      <Table method={'accounting.operations.total'} tableConfig={tableConfig} />
    </Menu>
  );
}

function AccountingClientDebtsPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
  }, [isAuthenticated, navigate]);

  const handleActionButtonClick = (destination) => {
    navigate(destination);
};

  const tableConfig = {
    'fields': [
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_full_name'),
        'key': 'client_full_name',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_phones'),
        'key': 'client_phones',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_orders_sum'),
        'key': 'client_orders_sum',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_user_fin_operations_sum'),
        'key': 'client_user_fin_operations_sum',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_bonus_history_order_subs_sum'),
        'key': 'client_bonus_history_order_subs_sum',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_balance'),
        'title_help_text': translate('AccountingClientDebtsPage.table.help_text.client_balance'),
        'key': 'client_balance',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_bonus_history_sum'),
        'title_help_text': translate('AccountingClientDebtsPage.table.help_text.client_balance'),
        'key': 'client_bonus_history_sum',
      },
    ],
  };

  return (
    <Menu>
      <Table method={'accounting.clients.debts'} tableConfig={tableConfig} />
    </Menu>
  );
}

function AccountingEmployeeDebtsPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();
  const [filtersData, setFiltersData] = useState([]);

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting')) navigate('/');
  }, [isAuthenticated, navigate]);

  const handleActionButtonClick = (destination) => {
    navigate(destination);
  };

  const formConfig = {
    'fields': [
      {
        'title': translate('AccountingCalendarPage.form.start'),
        'key': 'start',
        'type': 'datetime',
        'required': false,
      },
      {
        'title': translate('AccountingCalendarPage.form.end'),
        'key': 'end',
        'type': 'datetime',
        'required': false,
      }
    ]
  };

  const saveCustomHandler = async (data) => {
    setFiltersData(data);
  };

  const tableConfig = {
    'fields': [
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_full_name'),
        'key': 'client_full_name',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_balance'),
        'title_help_text': translate('AccountingClientDebtsPage.table.help_text.client_balance'),
        'key': 'client_employee_position',
        'type': 'select',
        'options': [
          { value: 1, label: translate('EmployeesPage.table.fields.cleaner') },
          { value: 2, label: translate('EmployeesPage.table.fields.cleaner_manager') },
          { value: 3, label: translate('EmployeesPage.table.fields.sales_manager') },
          { value: 4, label: translate('EmployeesPage.table.fields.office_manager') },
          { value: 5, label: translate('EmployeesPage.table.fields.quality_assurance') },
        ],
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_phones'),
        'key': 'client_phones',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_orders_sum'),
        'key': 'client_orders_sum',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_user_fin_operations_sum'),
        'key': 'client_user_fin_operations_sum',
      },
      {
        'title': translate('AccountingClientDebtsPage.table.fields.client_balance'),
        'title_help_text': translate('AccountingClientDebtsPage.table.help_text.client_balance'),
        'key': 'client_balance',
      },
    ],
  };

  return (
    <Menu>
      <div className='col-md-3 my-3'>
        <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
          <div className=''>
            <DynamicForm formConfig={formConfig} saveCustomHandler={saveCustomHandler} />
          </div>
        </div>

      </div>

      <div className='col-md-9 my-3'>
        <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
          <Table dynamicFilters={filtersData} method={'accounting.employees.debts'} tableConfig={tableConfig} />
        </div>
      </div>
    </Menu>
  );
}

function AccountingCalendarPage() {

  let { id } = useParams();
  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();
  const [calendarInfo, setCalendarInfo] = useState([]);

  const fetchData = async (start, end) => {
    const calendarData = await api.jsonRpcRequest('accounting.calendar', { employee_id: id, start, end});
    const events = calendarData && calendarData.objects ? calendarData.objects.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end)
    })) : [];
    setCalendarInfo(events);
  };

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('accounting_calendar')) navigate('/');
    fetchData();
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('AccountingCalendarPage.form.start'),
        'key': 'start',
        'type': 'datetime',
        'required': false,
      },
      {
        'title': translate('AccountingCalendarPage.form.end'),
        'key': 'end',
        'type': 'datetime',
        'required': false,
      }
    ]
  };

  const saveCustomHandler = async (data) => {
    fetchData(data.start, data.end);
  };

  const handleSelectEvent = async (event) => {
    if (event.type == 'employee_scheduled_session') {
      setTimeout(function () {
        navigate(`/employees/${event.employee_id}/sessions`)
      }, 10);
    }
    if (event.type == 'order_details') {
      setTimeout(function () {
        navigate(`/orders/${event.hash}`)
      }, 10);
    }
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    let orderColor = null;

    if (event.type == 'order_employees') {
      orderColor = '#1cb600';
    } else if (event.type == 'order_employees_work') {
      orderColor = '#ae9800';
    } else if (event.type == 'order_details') {
      orderColor = '#e06900';
    }
    
    return {
      style: {
        backgroundColor: orderColor 
      }
    };
  };

  return (
    <Menu>
      <div className='row'>
        <div className='col-3 my-3'>
          <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div className=''>
              <DynamicForm formConfig={formConfig} saveCustomHandler={saveCustomHandler} />
            </div>
          </div>

        </div>

        <div className='col-9 my-3'>
          <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <Calendar
              localizer={localizer}
              events={calendarInfo}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 1100 }}
              onSelectEvent={handleSelectEvent}
              formats={formats}
              defaultView="week"
              culture='ru'
              eventPropGetter={eventStyleGetter}
            />
          </div>
        </div>
      </div>
    </Menu>
  );
}

export { AccountingOperationsAdjuctionPage };
export { AccountingOperationsSubstractionPage };
export { AccountingOperationsTransferPage };
export { AccountingOperationsDebtsPage };
export { AccountingOperationsPage };
export { AccountingOperationsTotalPage };
export { AccountingClientDebtsPage };
export { AccountingEmployeeDebtsPage };
export { AccountingCalendarPage };