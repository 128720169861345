import React from 'react';

const Spinner = () => {
  return (
    <div style={{
      position: 'relative',
      width: '100%',
      height: '100%', // Растянуть на всю высоту родителя
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        borderWidth: '4px',
        borderStyle: 'solid',
        borderColor: 'rgb(52, 152, 219) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)',
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
      }}></div>

      {/* Анимация */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
}

export default Spinner;
