import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import { toast } from 'react-toastify';
import OrderAddress from '../components/OrderAddress';
import OrderItem from '../components/OrderItem';
import OrderTask from '../components/OrderTask';
import OrderEmployee from '../components/OrderEmployee';
import OrderEquipment from '../components/OrderEquipment';
import OrderEmployeeSearch from '../components/OrderEmployeeSearch';
import OrderScheduledDateTime from '../components/OrderScheduledDateTime';
import OrderPromoCode from '../components/OrderPromoCode';
import OrderComment from '../components/OrderComment';
import OrderAddPayment from '../components/OrderAddPayment';
import OrderPayments from '../components/OrderPayments';
import OrderState from '../components/OrderState';
import { colors } from '../theme';
import DynamicForm from '../components/DynamicForm';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import Modal from 'react-bootstrap/Modal';
import '../App.css';

import moment from 'moment';
import 'moment/locale/ru'; // Подключаем русскую локализацию moment.js

function convertMinutesToHumanReadable(minutes) {
  let duration = moment.duration(minutes, 'minutes');
  
  let hours = Math.floor(duration.asHours());
  let mins = duration.minutes();

  let formattedHours = String(hours).padStart(2, '0');
  let formattedMinutes = String(mins).padStart(2, '0');

  let humanReadable = `${formattedHours}:${formattedMinutes}`;

  return humanReadable;
}

function formatDateShort(isoDateString) {
  var date = new Date(isoDateString);
  var day = date.getDate().toString().padStart(2, '0');
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
  var year = date.getFullYear();
  var hours = date.getHours().toString().padStart(2, '0');
  var minutes = date.getMinutes().toString().padStart(2, '0');
  return day + "." + month + " " + hours + ":" + minutes;
}

function TasksPage() {

  function formatDate(isoDateString) {
    var date = new Date(isoDateString);
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
    var year = date.getFullYear();
    var hours = date.getHours().toString().padStart(2, '0');
    var minutes = date.getMinutes().toString().padStart(2, '0');
    return day + "." + month + "." + year + " " + hours + ":" + minutes;
  }

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('orders')) navigate('/');
  }, [isAuthenticated, navigate]);

  const tableConfig = {
    'fields': [
      {
        'title': translate('TasksPage.table.fields.order_address'),
        'key': 'order_address',
        'order_key': 'order__address_name',
      },
      {
        'title': translate('TasksPage.table.fields.text'),
        'key': 'text',
        'order_key': 'text',
      },
      {
        'title': translate('TasksPage.table.fields.responsible_user'),
        'key': 'responsible_user',
        'order_key': 'responsible_user_id',
      },
      {
        'title': translate('TasksPage.table.fields.deadline_datetime'),
        'type': 'date',
        'key': 'deadline_datetime',
        'order_key': 'deadline_datetime',
      },
      {
        'title': translate('TasksPage.table.fields.completed_datetime'),
        'type': 'date',
        'key': 'completed_datetime',
        'order_key': 'completed_datetime',
      },
      
    ],
    'filters': [
      {
        'title': translate('TasksPage.table.fields.responsible_user'),
        'key': 'responsible_user_id',
        'type': 'select',
        'options': config && config.users ? config.users.map(item => ({ value: item.id, label: item.full_name })) : [],
      },
      {
        'title': translate('OrdersPage.table.fields.additional_filter'),
        'key': 'additional_filter',
        'type': 'select',
        'options': [
          { value: 'all', label: translate('TasksPage.table.fields.additional_filter_all') },
          { value: 'open', label: translate('TasksPage.table.fields.additional_filter_active') },
          { value: 'closed', label: translate('TasksPage.table.fields.additional_filter_inactive') },
        ],
      },
    ]
  };

  return (
    <Menu>
      <Table initialFilters={{ additional_filter: 'open' }} method={'tasks.list'} tableConfig={tableConfig} editPage={'/orders/[order_hash]'} editPageTab={true} />
    </Menu>
  );
}


export { TasksPage };