import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import DynamicForm from '../components/DynamicForm';
import DynamicFormSet from '../components/DynamicFormSet';
import { translate } from '../utils/localization-helper';

function EquipmentsPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('equipments')) navigate('/');
  }, [isAuthenticated, navigate]);

  const tableConfig = {
    'fields': [
      {
        'title': translate('EquipmentsPage.table.fields.equipment_type'),
        'key': 'equipment_type',
        'order_key': 'equipment_type',
        'type': 'select',
      },
      {
        'title': translate('EquipmentsPage.table.fields.warehouse'),
        'key': 'warehouse',
        'order_key': 'warehouse_id',
        'type': 'select',
      },
      {
        'title': translate('EquipmentsPage.table.fields.hash'),
        'key': 'hash',
        'order_key': 'hash'
      },
      {
        'title': translate('EquipmentsPage.table.fields.serial_number'),
        'key': 'serial_number',
        'order_key': 'serial_number'
      },
      {
        'title': translate('EquipmentsPage.table.fields.manufacturer_name'),
        'key': 'manufacturer_name',
        'order_key': 'manufacturer_name'
      },
      {
        'title': translate('EquipmentsPage.table.fields.manufacturer_model'),
        'key': 'manufacturer_model',
        'order_key': 'manufacturer_model'
      },
      {
        'title': translate('EquipmentsPage.table.fields.description'),
        'key': 'description',
        'order_key': 'description'
      },
    ],
    'filters': [
      {
        'title': translate('EquipmentsPage.table.fields.equipment_type'),
        'key': 'company_id',
        'type': 'select',
        'options': config && config.equipment_types ? config.equipment_types.map(item => ({ value: item.id, label: item.name })) : []
      },
      {
        'title': translate('EquipmentsPage.table.fields.serial_number'),
        'key': 'serial_number__icontains',
        'type': 'text',
      },
      {
        'title': translate('EquipmentsPage.table.fields.manufacturer_name'),
        'key': 'manufacturer_name__icontains',
        'type': 'text',
      },
      {
        'title': translate('EquipmentsPage.table.fields.manufacturer_model'),
        'key': 'manufacturer_model__icontains',
        'type': 'text',
      },
      {
        'title': translate('EquipmentsPage.table.fields.description'),
        'key': 'description__icontains',
        'type': 'text',
      },
      {
        'title': translate('EquipmentsPage.table.fields.hash'),
        'key': 'hash__icontains',
        'type': 'text',
      },
    ]
  };

  return (
    <Menu>
      <Table method={'equipments.list'} updateMethod={'equipments.update'} tableConfig={tableConfig} addPage={'/equipments/add'} editPage={'/equipments/[id]'}  />
    </Menu>
  );
}

function EquipmentAddPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('equipments')) navigate('/');
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('EquipmentsPage.table.fields.equipment_type'),
        'key': 'equipment_type_id',
        'type': 'select',
        'options': config && config.equipment_types ? config.equipment_types.map(item => ({ value: item.id, label: item.name })) : [],
        'required': true,
      },
      {
        'title': translate('EquipmentsPage.table.fields.warehouse'),
        'key': 'warehouse_id',
        'required': true,
        'type': 'select',
        'options': config && config.warehouses ? config.warehouses.map(item => ({ value: item.id, label: item.name })) : [],
      },
      {
        'title': translate('EquipmentsPage.table.fields.serial_number'),
        'key': 'serial_number',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EquipmentsPage.table.fields.manufacturer_name'),
        'key': 'manufacturer_name',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EquipmentsPage.table.fields.manufacturer_model'),
        'key': 'manufacturer_model',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EquipmentsPage.table.fields.description'),
        'key': 'description',
        'type': 'text',
        'required': true,
      },
    ]
  };

  return (
    <Menu>
      <DynamicForm saveMethod={'equipments.add'} formConfig={formConfig} listLocation={'/equipments'} editPage={'/equipments/[id]'} />
    </Menu>
  );
}

function EquipmentPage() {

  let { id } = useParams();

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('equipments')) navigate('/');
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('EquipmentsPage.table.fields.equipment_type'),
        'key': 'equipment_type_id',
        'required': true,
        'type': 'select',
        'options': config && config.equipment_types ? config.equipment_types.map(item => ({ value: item.id, label: item.name })) : [],
      },
      {
        'title': translate('EquipmentsPage.table.fields.warehouse'),
        'key': 'warehouse_id',
        'required': true,
        'type': 'select',
        'options': config && config.warehouses ? config.warehouses.map(item => ({ value: item.id, label: item.name })) : [],
      },
      {
        'title': translate('EquipmentsPage.table.fields.serial_number'),
        'key': 'serial_number',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EquipmentsPage.table.fields.manufacturer_name'),
        'key': 'manufacturer_name',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EquipmentsPage.table.fields.manufacturer_model'),
        'key': 'manufacturer_model',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EquipmentsPage.table.fields.description'),
        'key': 'description',
        'type': 'text',
        'required': true,
      },
    ]
  };

  const formSetConfig = {
    fields: [
      {
        'key': 'file',
        'title': translate('EquipmentsPage.table.fields.file'),
        'type': 'file',
        'required': true
      }
    ],
    buttons: {
      'link': 'file_url',
    }
  };

  return (
    <Menu>
      <DynamicForm loadMethod={'equipments.info'} updateMethod={'equipments.update'} objectId={id} formConfig={formConfig} removeMethod={'equipments.remove'} listLocation={'/equipments'} />

      <DynamicFormSet
        formSetConfig={formSetConfig}
        loadFormSetMethod={'equipments.documents.list'}
        loadFormSetParams={{ equipment_id: id }}
        updateFormSetMethod={'equipments.documents.update'}
        saveFormSetMethod={'equipments.documents.add'}
        saveFormSetParams={{ equipment_id: id }}
        removeFormSetMethod={'equipments.documents.remove'}
      />
    </Menu>
  );
}

export {EquipmentPage};
export {EquipmentAddPage};
export {EquipmentsPage};