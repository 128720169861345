import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from './DynamicForm';
import { colors } from '../theme';
import '../App.css';


function OrderSaleScript({ orderInfo, onCompleteFunction, initialData }) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();
    const [saleScriptInfo, setSaleScriptInfo] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        let data = await api.jsonRpcRequest('orders.sale_script.steps', { hash: orderInfo.hash });
        setSaleScriptInfo(data);
    };

    const addStep = async (buttonId) => {
        await api.jsonRpcRequest('orders.sale_script.step', { hash: orderInfo.hash, sale_script_step_button_id: buttonId });
        fetchData();
    };

    return (
        <div>
            <div class="m-3">
                

                {saleScriptInfo.sale_script_step_history ? saleScriptInfo.sale_script_step_history.map((item, index) => (
                    <div className='p-3 mt-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
                        <p class="sale-script-text" dangerouslySetInnerHTML={{__html: item.content}}>
                        
                        </p>
                        {item.buttons ? <div style={{ marginTop: '20px' }}>
                            {item.buttons.map((btn, index) => (
                                <span onClick={() => addStep(btn.id)} className={btn.is_active ? 'sale-script-action-button-active' : 'sale-script-action-button'}>{ btn.name }</span>
                            ))}
                        </div> : null}
                    </div>
                )) : null}

                {saleScriptInfo.sale_script_step ? <div className='p-3 mt-3' style={{ backgroundColor: colors.backgroundActiveSaleScript, borderRadius: '10px' }}>
                    <p class="sale-script-text" dangerouslySetInnerHTML={{__html: saleScriptInfo.sale_script_step.content}}>
                        
                    </p>
                    {saleScriptInfo.sale_script_step_buttons ? <div style={{ marginTop: '20px' }}>
                        {saleScriptInfo.sale_script_step_buttons.map((btn, index) => (
                            <span onClick={() => addStep(btn.id)} className={btn.is_active ? 'sale-script-action-button-active' : 'sale-script-action-button'}>{ btn.name }</span>
                        ))}
                    </div> : null}
                </div> : null}
            </div>
        </div>
    );
}

export default OrderSaleScript;
