import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip } from 'chart.js';
import '../App.css';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);

function AnalyticsLineChart({ method, filterData }) {

    const [data, setData] = useState();

    const fetchData = async () => {
        if(!filterData) return;
        
        setData(null);
        try{
            const data = await api.jsonRpcRequest(method, filterData);
            if (data) {
                setData(data);
            }
        } catch {

        }
    };

    useEffect(() => {
        fetchData();
    }, [filterData]);

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <div style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {data && data.labels && data.labels.length > 0 ? (
                <Line data={data} options={options} />
            ) : (
                <div className="spinner">
                    <div style={{ border: "4px solid rgba(0, 0, 0, 0.1)", width: "36px", height: "36px", borderRadius: "50%", borderTop: "4px solid #3498db", animation: "spin 1s linear infinite" }}></div>
                </div>
            )}
        </div>
    );
}

export default AnalyticsLineChart;
