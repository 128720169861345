import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function OrderAddPayment({ orderInfo, onCompleteFunction}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {

    }, []);

    const saveCustomHandler = async (data) => {
        let updateData = {
            order_id: orderInfo.id,
            amount: data.amount,
            currency: data.currency,
            notes: data.notes,
            wallet_id: data.wallet
        }
        await api.jsonRpcRequest('orders.accounting.operations.adjuction', updateData);
        if(onCompleteFunction) onCompleteFunction();
    };

    const formConfig = {
        'fields': [
          {
            'title': translate('AccountingOperationsPage.form.amount'),
            'key': 'amount',
            'type': 'number',
            'required': true,
          },
          {
            'title': translate('AccountingOperationsPage.form.currency'),
            'key': 'currency',
            'type': 'select',
            'required': true,
            'options': config && config.currencies ? config.currencies.map(item => ({ value: item[0], label: item[1] })) : [],
          },
          {
            'title': translate('AccountingOperationsPage.form.wallet'),
            'key': 'wallet',
            'type': 'select',
            'required': true,
            'options': config && config.wallets ? config.wallets.map(item => ({ value: item.id, label: item.name })) : [],
          },
          {
            'title': translate('AccountingOperationsPage.form.notes'),
            'key': 'notes',
            'type': 'select',
            'required': false,
            'options': [{ value: 'Готівка', label: 'Готівка' }, { value: 'Рахунок', label: 'Рахунок' }, { value: 'Картка', label: 'Картка' }],
          }
        ]
      };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} initialData={orderInfo} />
        </div>
    );
}

export default OrderAddPayment;
