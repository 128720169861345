import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';

import { EquipmentsPage, EquipmentPage, EquipmentAddPage } from './pages/EquipmentsPage';
import { EmployeesPage, EmployeePage, EmployeeSessionsPage, EmployeeAddPage } from './pages/EmployeesPage';
import { DocumentsPage, DocumentPage, DocumentAddPage, DocumentAddArticlePage, DocumentsStockPage } from './pages/DocumentsPage';
import { ReportOrdersPage } from './pages/ReportsPage';
import { OrdersPage, OrderPage, OrderAddPage } from './pages/OrdersPage';
import { TasksPage } from './pages/TasksPage';
import { AnalyticsFacebookPage, AnalyticsGooglePage, AnalyticsCommonPage } from './pages/AnalyticsPage';
import { TOTPPage } from './pages/UtilsPage';
import { JournalPage, JournalAddPage } from './pages/JournalPage';
import { AccountingCalendarPage, AccountingOperationsPage, AccountingOperationsAdjuctionPage, AccountingOperationsSubstractionPage, AccountingOperationsTransferPage, AccountingOperationsDebtsPage, AccountingOperationsTotalPage, AccountingClientDebtsPage, AccountingEmployeeDebtsPage } from './pages/AccountingPage';


function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />

          <Route path="/utils/totp" element={<TOTPPage />} />

          <Route path="/journal" element={<JournalPage />} />
          <Route path="/journal/add" element={<JournalAddPage />} />

          <Route path="/employees" element={<EmployeesPage />} />
          <Route path="/employees/add" element={<EmployeeAddPage />} />
          <Route path="/employees/:id/sessions" element={<EmployeeSessionsPage />} />
          <Route path="/employees/:id" element={<EmployeePage />} />

          <Route path="/analytics/reports/orders" element={<ReportOrdersPage />} />

          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/documents/add" element={<DocumentAddPage />} />
          <Route path="/documents/stock" element={<DocumentsStockPage />} />
          <Route path="/documents/:id" element={<DocumentPage />} />
          <Route path="/documents/:id/add" element={<DocumentAddArticlePage />} />

          <Route path="/equipments" element={<EquipmentsPage />} />
          <Route path="/equipments/add" element={<EquipmentAddPage />} />
          <Route path="/equipments/:id" element={<EquipmentPage />} />

          <Route path="/tasks" element={<TasksPage />} />

          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/orders/add" element={<OrderAddPage />} />
          <Route path="/orders/:hash" element={<OrderPage />} />

          <Route path="/accounting" element={<AccountingOperationsPage />} />
          <Route path="/accounting/calendar" element={<AccountingCalendarPage />} />
          <Route path="/accounting/adjuction" element={<AccountingOperationsAdjuctionPage />} />
          <Route path="/accounting/substraction" element={<AccountingOperationsSubstractionPage />} />
          <Route path="/accounting/transfer" element={<AccountingOperationsTransferPage />} />
          <Route path="/accounting/debts" element={<AccountingOperationsDebtsPage />} />
          <Route path="/accounting/total" element={<AccountingOperationsTotalPage />} />
          <Route path="/accounting/client/debts" element={<AccountingClientDebtsPage />} />
          <Route path="/accounting/employee/debts" element={<AccountingEmployeeDebtsPage />} />

          <Route path="/analytics/facebook" element={<AnalyticsFacebookPage />} />
          <Route path="/analytics/google" element={<AnalyticsGooglePage />} />
          <Route path="/analytics/common" element={<AnalyticsCommonPage />} />

        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
