import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import DynamicForm from '../components/DynamicForm';
import DynamicFormSet from '../components/DynamicFormSet';
import { colors } from '../theme';
import { api } from '../utils';
import { toast } from 'react-toastify';
import DocumentMoveWarehouse from '../components/DocumentMoveWarehouse';
import { translate } from '../utils/localization-helper';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import 'moment/locale/uk'; // Подключаем русскую локализацию moment.js

const localizer = momentLocalizer(moment);

function formatDateShort(isoDateString) {
  var date = new Date(isoDateString);
  var day = date.getDate().toString().padStart(2, '0');
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
  var year = date.getFullYear();
  var hours = date.getHours().toString().padStart(2, '0');
  var minutes = date.getMinutes().toString().padStart(2, '0');
  return day + "." + month + " " + hours + ":" + minutes;
}

// Настраиваем формат времени на 24 часа
const formats = {
  timeGutterFormat: (date, culture, localizer) =>
    localizer.format(date, 'HH:mm', culture),
  eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
    let startDate = localizer.format(start, 'HH:mm', culture);
    let endDate = localizer.format(end, 'HH:mm', culture);
    return `${startDate} - ${endDate}`;
  },
};


function ReportOrdersPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('employees')) navigate('/');
  }, [isAuthenticated, navigate]);

  const tableConfig = {
    'fields': [
      
    ],
    'filters': [

    ]
  };

  return (
    <Menu>
      <Table method={'analytics.reports.orders'} editPage={'/orders/[hash]'} editPageTab={true} tableConfig={tableConfig} />
    </Menu>
  );
}


export { ReportOrdersPage };