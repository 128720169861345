import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { api } from '../utils';
import { colors } from '../theme';
import { translate } from '../utils/localization-helper';

const DynamicFormSet = ({
  loadFormSetMethod,
  loadFormSetParams,
  updateFormSetMethod,
  saveFormSetMethod,
  saveFormSetParams,
  removeFormSetMethod,
  formSetConfig
}) => {
  const [objects, setObjects] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await api.jsonRpcRequest(loadFormSetMethod, loadFormSetParams);
    setObjects(data && Array.isArray(data.objects) ? data.objects : []);
  };

  const handleInputChange = (e, key, index) => {
    const { value, checked, type } = e.target;
    
    setFormData(prevData => ({
      ...prevData,
      [index]: {
        ...prevData[index],
        [key]: type === 'checkbox' ? checked : value
      }
    }));
  };

  const handleFileChange = async (e, key, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData(prevData => ({
          ...prevData,
          [index]: {
            ...prevData[index],
            [key]: reader.result
          }
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
  };

  const needShowView = (index) => {
    return objects[index] && formSetConfig.buttons['link'] && objects[index][formSetConfig.buttons['link']];
  };

  const handleView = (index) => {
    window.open(objects[index][formSetConfig.buttons['link']], "_blank", "noreferrer");
  };

  const handleSave = async (index) => {
    if (objects[index] && objects[index]['id']) {
      await api.jsonRpcRequest(updateFormSetMethod, { ...objects[index], ...formData[index] });
    } else {
      await api.jsonRpcRequest(saveFormSetMethod, { ...saveFormSetParams, ...objects[index], ...formData[index] });
    }
    fetchData();
    setEditIndex(-1);
  };

  const handleCancel = () => {
    setEditIndex(-1);
  };

  const handleAdd = () => {
    setObjects(prevObjects => [...prevObjects, {}]);
    setEditIndex(objects.length);
  };

  const handleRemove = async (index) => {
    const confirmDelete = window.confirm(translate('common.form.confirm_delete'));

    if (confirmDelete) {
      await api.jsonRpcRequest(removeFormSetMethod, { ...objects[index] });
      fetchData();
    }
    setEditIndex(-1);
  };

  const handleSubmit = async (index) => {
    const requiredFields = formSetConfig.fields.filter(field => field.required && !formData[index]?.[field.key]);
    if (requiredFields.length > 0) {
      const newErrors = {};
      requiredFields.forEach(field => {
        newErrors[field.key] = `${field.title} is required.`;
      });
      return;
    }


    if (editIndex === index) {
      const formDataKeys = formSetConfig.fields.map(field => field.key);
      const filteredFormData = Object.keys(formData[index])
        .filter(key => formDataKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = formData[index][key];
          return obj;
        }, {});

      let updatedData;
      if (filteredFormData.id) {
        updatedData = await api.jsonRpcRequest(updateFormSetMethod, filteredFormData);
      } else {
        updatedData = await api.jsonRpcRequest(saveFormSetMethod, { ...saveFormSetParams, ...filteredFormData });
      }

      setObjects(prevObjects => prevObjects.map((obj, i) => (i === index ? updatedData : obj)));
      setEditIndex(-1);
    }
  };

  return (
    <div style={{ flex: 1, backgroundColor: colors.backgroundSecondary, padding: '10px', borderRadius: '10px' }} className="my-3">
      <Button variant="success" onClick={handleAdd}><i className="bi bi-plus"></i></Button>
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        {objects.map((object, index) => (
          <Form key={index} onSubmit={(e) => { e.preventDefault(); handleSubmit(index); }}>
            <div style={{ marginTop: index === 0 ? '20px' : 0, marginBottom: index === objects.length - 1 ? 0 : '20px', display: 'flex', alignItems: 'center', width: '100%' }}>
              {formSetConfig.fields.map(({ key, title, placeholder, type, options, link }, fieldIndex) => (
                <div key={fieldIndex} style={{ marginRight: '10px', width: '100%' }}>
                  <Form.Label>{title}</Form.Label>
                  {!needShowView(index) && type === 'boolean' ? (
                    <Form.Check
                      type="checkbox"
                      id={`checkbox-${index}-${key}`}
                      label={title}
                      checked={formData[index] && formData[index][key] !== undefined ? formData[index][key] : object[key]}
                      onChange={(e) => handleInputChange(e, key, index)}
                      disabled={editIndex !== index}
                    />
                  ) : !needShowView(index) && type === 'file' ? (
                    <Form.Control type="file" onChange={e => handleFileChange(e, key, index)} />
                  ) : !needShowView(index) && type === 'select' ? (
                    <Form.Control as="select" value={formData[index] && formData[index][key] ? formData[index][key] : object[key] || ''} onChange={(e) => handleInputChange(e, key, index)} disabled={editIndex !== index}>
                      <option value="">- - -</option>
                      {options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.value}>{option.label}</option>
                      ))}
                    </Form.Control>
                  ) : !needShowView(index) && type === 'select_group' ? (
                    <Form.Control as="select" value={formData[index] && formData[index][key] ? formData[index][key] : object[key] || ''} onChange={(e) => handleInputChange(e, key, index)} disabled={editIndex !== index}>
                      <option value="">- - -</option>
                      {options.map((group, index) => (
                        <optgroup key={index} label={group.label}>
                          {group.options.map((option, idx) => (
                            <option key={idx} value={option.value} selected={formData[key] === option.value ? true : false}>{option.label}</option>
                          ))}
                        </optgroup>
                      ))}
                    </Form.Control>
                  ) : needShowView(index) ? (
                    <Form.Control type={type === 'number' ? 'number' : 'text'} placeholder={placeholder} value={object[formSetConfig.buttons['link']] || ''} onChange={(e) => handleInputChange(e, key, index)} disabled={editIndex !== index} />
                  ) : (
                    <Form.Control type={type === 'number' ? 'number' : 'text'} placeholder={placeholder} value={formData[index] && formData[index][key] ? formData[index][key] : object[key] || ''} onChange={(e) => handleInputChange(e, key, index)} disabled={editIndex !== index} />
                  )}
                </div>
              ))}
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '32px' }}>
                { needShowView(index) ? (<Button variant="primary" style={{ marginRight: '10px' }} onClick={() => handleView(index)}>{translate('common.form.view')}</Button>) : null }
                {editIndex === index ? (
                  <>
                    <Button variant="success" onClick={() => handleSave(index)} style={{ marginRight: '10px' }}><i className="bi bi-check"></i></Button>
                    <Button variant="danger" onClick={handleCancel} style={{ marginRight: '10px' }}><i className="bi bi-x"></i></Button>
                  </>
                ) : (
                  <Button variant="warning" onClick={() => handleEdit(index)} style={{ marginRight: '10px' }}><i className="bi bi-pencil"></i></Button>
                )}
                <Button variant="danger" onClick={() => handleRemove(index)}><i className="bi bi-trash"></i></Button>
              </div>
            </div>
          </Form>
        ))}
      </div>
    </div>
  );
};

export default DynamicFormSet;
