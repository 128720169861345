export const ru = {
    'errors': {
        'promoCodeNotFound': 'Промокод не найден!',
        'promoCodeTotalActivationsExpired': 'Промокод активирован максимальное кол-во раз!',
        'promoCodeClientActivationsExpired': 'Промокод активирован клиентом максимальное кол-во раз!'
    },
    'LoginPage': {
        'emailLabel': 'Ваша почта',
        'passwordLabel': 'Пароль',
        'submitBtnLabel': 'Войти'
    },
    'Menu': {
        'employees': 'Сотрудники',
        'equipment': 'Оборудование',
        'orders': 'Заказы',
        'accounting': 'Бухгалтерия',
        'reports': 'Отчеты',
        'client_debts': 'Клиенты (долги)',
        'employee_debts': 'Сотрудники (долги)',

        'home': 'Dashboard',
        'articles': 'Товары',
        'categories': 'Категории',
        'products_extra_groups': 'Опции',
        'analytics': 'Аналитика',
        'journal': 'Журнал',
        'fb': 'Facebook',
        'google': 'Google Ads',
    },
    'EquipmentsPage': {
        'table': {
            'fields': {
                'equipment_type': 'Тип',
                'warehouse': 'Склад',
                'hash': 'Код',
                'serial_number': 'Серийный номер',
                'manufacturer_name': 'Бренд',
                'manufacturer_model': 'Модель',
                'description': 'Описание',
                'file': 'Файл',
            }
        }
    },
    'AccountingOperationsPage': {
        'table': {
            'fields': {
                'date': 'Дата',
                'user_email': 'Пользователь',
                'amount': 'Сумма',
                'currency': 'Валюта',
                'usd_amount': 'USD',
                'expense_item_name': 'Тип',
                'notes': 'Примечание',
                'debt_from': 'Кто должен',
                'debt_to': 'Кому должен',
                'in': 'Приход',
                'out': 'Расход',
                'balance': 'Баланс',
            },
            'actionButtons': {
                'adjuction': 'Приход',
                'substraction': 'Расход',
                'transfer': 'Трансфер',
                'exchange': 'Обмен',
                'debts': 'Долги',
                'total': 'Итог',
            }
        },
        'form': {
            'amount': 'Сумма',
            'notes': 'Заметка',
            'currency': 'Валюта',
            'to_user_id': 'Пользователь которому сделали перевод',
            'expense_item': 'Тип платежа'
        }
    },
    'EmployeesPage': {
        'table': {
            'fields': {
                'full_name': 'Полное имя',
                'short_name': 'Имя',
                'phone': 'Телефон',
                'home_address': 'Домашний адрес',
                'gov_id_number': 'Номер паспорта',
                'gov_id_date': 'Дата выдачи паспорта',
                'gov_id_office': 'Орган выдачи паспорта',
                'photo': 'Фото',
                'verified': 'Проверен',
                'not_verified': 'Не проверен',
            },
            'links': {
                'sessions': 'Смены'
            }
        }
    },
    'OrderAddress': {
        'form': {
            'address_locality': 'Город',
            'address_name': 'Улица, дом',
            'address_apartment': 'Квартира',
            'address_comment': 'Заметка',
        }
    },
    'OrderEmployeeSearch': {
        'form': {
            'assign': 'Назначить'
        }
    },
    'OrderItem': {
        'form': {
            'service': 'Услуга',
        }
    },
    'OrderEmployee': {
        'form': {
            'employee': 'Ответственный',
        }
    },
    'OrderEquipment': {
        'form': {
            'equipment': 'Оборудование',
        }
    },
    'OrdersPage': {
        'table': {
            'fields': {
                'address_name': 'Адрес',
                'promo_code': 'Промокод',
                'client_full_name': 'Клиент',
                'bonus_balance': 'Бонусный баланс',
                'balance': 'Баланс',
                'client_phone': 'Контактный телефон',
                'scheduled_datetime': 'Время начала',
                'price': 'Сумма заказа',
                'user_fin_operations_sum': 'Сумма оплат',
                'state': 'Статус',
            }
        },
        'form': {
            'pay': 'Оплаты (нал/безнал)'
        }
    },
    'AccountingClientDebtsPage': {
        'table': {
            'fields': {
                'client_full_name': 'Имя клиента',
                'client_phones': 'Контактный телефон',
                'client_orders_sum': 'Сумма заказов',
                'client_user_fin_operations_sum': 'Сумма оплат (нал/безнал)',
                'client_bonus_history_order_subs_sum': 'Сумма оплат (бонусы)',
                'client_balance': 'Баланс',
                'client_bonus_history_sum': 'Бонусный баланс',
            },
            'help_text': {
                'client_balance': 'Если меньше 0, значит должны нам. Если больше 0, значит должны мы.'
            }
        }
    },
    'EmployeeSessionsPage': {
        'form': {
            'session_start': 'Начало смены',
            'session_end': 'Завершение смены',
        }
    },
    'JournalPage': {
        'form': {
            'session_start': 'Начало смены',
            'session_end': 'Завершение смены',
        },
        'table': {
            'actionButtons': {
                'add_event': ''
            },
            'fields': {
                'user_email': '',
                'create_time': '',
                'content': '',
            },
        }
    },
    'common': {
        'table': {
            'actions': 'Действия',
            'add': 'Добавить',
            'search_staff': 'Подобрать ответственных'
        },
        'form': {
            'save': 'Сохранить',
            'remove': 'Удалить',
            'required': 'Обязательное поле',
            'confirm_delete': 'Уверены ли вы, что хотите удалить?',
            'filter': 'Фильтр',
            'empty': 'не указано',
            'view': 'Посмотреть',
            'close': 'Закрыть',
        },
        'toast': {
            'saved': 'Сохранено',
            'removed': 'Удалено',
            'no_employees_fot_order': 'Нет исполнтелей на заказ',
        },
        'order': {
            'states': {
                'new': 'Новый',
                'wait_for_confirmation': 'Ожидает подтверждение',
                'confirmed': 'Подтвержден',
                'in_process': 'В работе',
                'finished': 'Работа завершена',
                'completed': 'Выполнен',
                'canceled': 'Отменен'
            }
        }
    },
    'TOTPPage': {
        'form': {
            'totp_secret': 'Сканируй qr код и нажимай кнопку сохранить'
        }
    },
    'OrderComment': {
        'form': {
            'comment': 'Комментарий'
        }
    },
}