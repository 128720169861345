import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import DynamicForm from '../components/DynamicForm';
import OrderEmployeeSearchItem from '../components/OrderEmployeeSearchItem';
import { colors } from '../theme';

function OrderEmployeeSearch({ orderInfo, onCompleteFunction }) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    const [modalDispatchItemInfo, setModalDispatchItemInfo] = useState(false);
    const handleCloseModalDispatchItemInfo = () => setModalDispatchItemInfo(false);
    const handleShowModalDispatchItemInfo = () => setModalDispatchItemInfo(true);
    const [dispatchItemInfo, setDispatchItemInfo] = useState(false);
    const [dispatchItemDate, setDispatchItemDate] = useState(null);

    useEffect(() => {
    }, []);

    const renderSuggestion = (suggestion) => (
        <div>
            {suggestion.full_name}
        </div>
    );

    const getServiceSuggestions = async (value, key) => {
        const inputValue = value.trim().toLowerCase();

        let data = await api.jsonRpcRequest('employees.list', { filters: { full_name__icontains: inputValue } });
        data = data.objects.map(item => {
            return { ...item, id: item.id, name: item.full_name };
        });
        return data;
    };

    const onSelectItem = async (data, date) => {
        setDispatchItemInfo(data);
        setDispatchItemDate(date);
        handleShowModalDispatchItemInfo();
    };

    const onAssignItem = async () => {
        let dataDispatch = await api.jsonRpcRequest('orders.dispatch.search', { id: orderInfo.id, date: dispatchItemDate, create: true });
        handleCloseModalDispatchItemInfo();
        if(dataDispatch.state == 1){
            toast.success(translate('common.toast.saved'));
        }
        else {
            toast.success(translate('common.toast.no_employees_fot_order'));
        }
        if(onCompleteFunction){
            onCompleteFunction(dataDispatch);
        }
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrderEmployee.form.employee'),
                'key': 'employee_id',
                'getSuggestionsFunction': getServiceSuggestions,
                'renderSuggestionFunction': renderSuggestion,
                'type': 'autocomplete',
                'required': true,
            },
        ]
    };

    function generateDateArray() {
        var currentDate = new Date();
        var dateArray = [];

        dateArray.push(currentDate);

        for (var j = 1; j <= 7; j++) {
            var nextDate = new Date();
            nextDate.setDate(currentDate.getDate() + j);
            dateArray.push(nextDate);
        }

        return dateArray;
    }

    function formatDate(date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        return (day < 10 ? '0' : '') + day + '.' + (month < 10 ? '0' : '') + month;
    }

    let dates = generateDateArray();
    let daysOfWeek = [];
    dates.forEach(function (date) {
        daysOfWeek.push(formatDate(date));
    });
    const hoursOfDay = Array.from(Array(24).keys());

    return (
        <div>
            <table className="schedule-table">
                <thead>
                    <tr>
                        <th></th>
                        {dates.map(date => (
                            <th key={date}>{formatDate(date)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {hoursOfDay.map(hour => (
                        <tr key={hour}>
                            <td className="hour-cell">{`${hour.toString().padStart(2, '0')}:00`}</td>
                            {dates.map(date => (
                                <td key={formatDate(date) + hour} className="schedule-cell">
                                    <OrderEmployeeSearchItem onSelect={onSelectItem} date={date} hour={hour} orderInfo={orderInfo} />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal show={modalDispatchItemInfo} onHide={handleCloseModalDispatchItemInfo} style={{ zIndex: 2050 }}>
                <Modal.Body>
                    {dispatchItemInfo && dispatchItemInfo.order_items ? dispatchItemInfo.order_items.map((item, index) => (
                        <div>
                            <div className="orderInfoItem">
                                <div className="label">{translate('OrderItem.form.service')}</div>
                                <div className="value">{item.order_item.order_type_item.name}</div>
                            </div>
                            <div className="orderInfoItem">
                                <div className="label">{translate('Menu.employees')}</div>
                                {item.employees && item.employees.length ? item.employees.map((employee, index) => (
                                    <div className="value">{employee.full_name} {employee.verified ? <span class="badge bg-success">{translate('EmployeesPage.table.fields.verified')}</span> : <span class="badge bg-warning">{translate('EmployeesPage.table.fields.not_verified')}</span>}</div>
                                )) : (
                                    <div className="value">{translate('common.form.empty')}</div>
                                )}

                            </div>
                        </div>
                    )) : null}

                    {dispatchItemInfo && dispatchItemInfo.state == 1 ? (
                        <div>
                            <Button onClick={onAssignItem} variant="success" type="submit" className='mt-3'>
                                {translate('OrderEmployeeSearch.form.assign')}
                            </Button>

                            <Button onClick={handleCloseModalDispatchItemInfo} variant="secondary" type="submit" className='mt-3 mx-3'>
                                {translate('common.form.close')}
                            </Button>
                        </div>
                    ) : null}

                    {dispatchItemInfo && dispatchItemInfo.state > 1 ? (
                        <div>
                            <Button onClick={handleCloseModalDispatchItemInfo} variant="secondary" type="submit" className='mt-3'>
                                {translate('common.form.close')}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default OrderEmployeeSearch;
