import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import Table from '../components/Table';

function OrderEmployeePayments({ orderInfo, onCompleteFunction, addOrderEmployeePaymentHandler }) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();
    const [initialData, setInitialData] = useState({});

    useEffect(() => {
        setInitialData({ ...orderInfo });
    }, []);

    const tableConfig = {
        'fields': [
            {
                'title': translate('OrderEmployeeAddPayment.form.employee'),
                'key': 'employee__full_name',
                'type': 'text',
                'order_key': 'amount',
            },
            {
                'title': translate('OrderEmployeeAddPayment.form.amount'),
                'key': 'amount',
                'type': 'text',
                'order_key': 'amount',
            },
        ]
    };

    return (
        <div>
            <Table method={'employee.payments.list'} tableConfig={tableConfig} initialFilters={{ order__hash: orderInfo.hash }} addPageHandler={addOrderEmployeePaymentHandler} />
        </div>
    );
}

export default OrderEmployeePayments;
